/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */

export default function PageMeta({ children }) {
  return children({
    'frontend/art': {
      metaTitle: "Parcourir l'art | Acheter de l'art contemporain en ligne | Rise Art",
      metaDescription: null,
      metaKeywords:
        'contemporary art, contemporary arts, contemporary abstract art, art dealers, art for sale, art websites, buy art, buy art online, original art, original art for sale, buy original art, art original, canvas wall art, fine art, fine art for sale, buy fine art online, framed art, modern art, modern art for sale, buy modern art online, online art, online art gallery, art advisors, art advisory service, art dealer, online art dealer, curated art, art curation, interior design help, art for the office, office art',
      pageTitle: "Art contemporain à vendre. Achat d'art en ligne. Galerie d'art en ligne.",
    },
  });
}
